
.pagination-wrap {
    display: flex;
    align-items: center;
    .total-page {
        font-size: 14px;
        color: #333;
        margin-right: 4px;
    }
}
.title-preprint {
    width: 100%;
    height: 44px;
    background: #f5fbff;
    padding: 0 16px 0 12px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    h5 {
        color: #2b221b;
        font-size: 22px;
        padding-left: 14px;
        position: relative;
        line-height: 44px;
        font-weight: bold;
    }
    h5::after {
        content: '';
        width: 8px;
        height: 11px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(../assets/img/oa/icon-title-tip.png);
        background-size: 100% 100%;
    }
    a {
        font-size: 14px;
        line-height: 44px;
        color: #777777;
        cursor: pointer;
        img {
            width: 12px;
            height: 15px;
            margin-left: 3px;
            vertical-align: middle;
        }
    }
}

.title-preprint {
    h5 {
        display: flex;
        p {
            //width: 156px;
            font-size: 18px;
            color: #2b221b;
            font-weight: normal;
            cursor: pointer;
        }
        p.first-title-plat {
            position: relative;
            margin-right: 70px;
        }
        p.first-title-plat::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -35px;
            transform: translateY(-50%);
            width: 1px;
            height: 16px;
            background: #d1d6d9;
        }
        p.sec-title-plat {
        }

        p.active-title-plat {
            font-size: 22px;
            font-weight: bold;
        }
    }
}
.preprint-page {
    .top-banner-wrap {
        width: 100%;
        //background: #cb2f3c;
        min-height: 300px;
        .top-banner-component {
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 0;
            margin-top: 140px;
            margin-bottom: 50px;
            .left-top-banner {
                position: absolute;
                left: 74px;
                bottom: 0;
                background: rgba(255, 255, 255, 0.9);
                z-index: 2;
                width: 238px;
                height: 412px;
                padding: 22px 16px;
                box-sizing: border-box;
                z-index: 100;
                //border-top: 1px solid #C30D23;
                //border-image: linear-gradient(180deg, #C30D23, #C30D23) 10 10;
                border: 1px solid #fff;
                border-image: linear-gradient(#c30d23, #fff) 50 50;
                //border-image: -webkit-linear-gradient(red,#fff) 30 30;
                //border-image: -moz-linear-gradient(red,#fff) 30 30;
                h5 {
                    font-size: 22px;
                    line-height: 23px;
                    color: #2b221b;
                    font-weight: bold;
                    margin-bottom: 12px;
                    margin-bottom: 11px;
                }
                h6 {
                    font-size: 18px;
                    line-height: 16px;
                    color: #828282;
                    margin-bottom: 35px;
                }
                div {
                    z-index: 10;
                    .banner-content {
                        text-indent: 35px;
                        font-size: 14px;
                        line-height: 26px;
                        color: #2b221b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 9;
                        -webkit-box-orient: vertical;
                    }
                    span {
                        font-size: 14px;
                        color: #2b221b;
                        float: right;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                }
            }
            .right-top-banner {
                width: 1200px;
            }
        }
    }

    .preprint-main {
        .main-top {
            display: flex;
            justify-content: space-between;
            .latest-release {
                width: 880px;
                ul.title-btn {
                    display: flex;
                    margin-top: 20px;
                    li {
                        width: 176px;
                        height: 34px;
                        font-size: 14px;
                        color: #2b221b;
                        text-align: center;
                        line-height: 34px;
                        box-sizing: border-box;
                        border: 1px solid #eaeff3;
                        cursor: pointer;
                    }
                    li.active-title {
                        background: #c30d23;
                        color: #fff;
                        border: 1px solid #c30d23;
                    }
                }
                ul.article-list {
                    margin-top: 27px;
                    li {
                        margin-top: 18px;
                        padding-bottom: 14px;
                        border-bottom: 1px solid #eaeff3;
                        display: flex;

                        a {
                            margin-left: 2px;
                            margin-right: 11px;
                            margin-top: -4px;
                            img {
                                width: 28px;
                                //height: 21px;
                                cursor: pointer;
                            }
                        }
                        .article-content {
                            h6 {
                                color: #2b221b;
                                font-size: 16px;
                                line-height: 16px;
                                cursor: pointer;
                                span {
                                    display: inline-block;
                                    height: 20px;
                                    line-height: 20px;
                                    padding: 0 9px;
                                    background: #eaeff3;
                                    border-radius: 0px 10px 10px 10px;
                                    color: #828282;
                                    font-size: 12px;
                                }
                            }
                            div {
                                display: flex;
                                p {
                                    width: 280px;
                                    color: #828282;
                                    font-size: 14px;
                                    padding-right: 20px;
                                    line-height: 15px;
                                    margin-top: 14px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
            }
            .contribute-news-wrap {
                button {
                    border: none;
                    width: 299px;
                    height: 56px;
                    display: block;
                    background: url(../assets/img/oa/bg-contribution.png);
                    background-size: 100% 100%;
                    margin-bottom: 20px;
                    a {
                        display: block;
                        width: 299px;
                        height: 56px;
                        line-height: 56px;
                        font-size: 24px;
                        color: #fff;
                        font-weight: bold;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
            .news-preprint {
                ul {
                    padding-top: 11px;
                    border: 1px solid #eaeff3;
                    padding-bottom: 16px;
                    li {
                        box-sizing: border-box;
                        padding: 0 19px 0 33px;
                        position: relative;
                        a {
                            width: 245px;
                            display: block;
                            padding-left: 10px;
                            font-size: 14px;
                            color: #2b221b;
                            line-height: 45px;
                            border-bottom: 1px solid #eaeff3;
                            box-sizing: border-box;
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        //a::before {
                        //    content: '';
                        //    width: 6px;
                        //    height: 6px;
                        //    background: #C30D23;
                        //    border-radius: 50%;
                        //    position: absolute;
                        //    left: -12px;
                        //    top: 50%;
                        //    transform: translateY(-50%);
                        //}
                    }
                    li::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        background: #c30d23;
                        border-radius: 50%;
                        position: absolute;
                        left: 21px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    li:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .platform-dynamics {
            margin-top: 48px;
            display: flex;
            justify-content: space-between;
            .platform-left {
                width: 880px;
                .title-preprint {
                    h5 {
                        display: flex;
                        p {
                            font-size: 18px;
                            color: #2b221b;
                            font-weight: normal;
                            cursor: pointer;
                        }
                        p.first-title-plat {
                            position: relative;
                            margin-right: 70px;
                        }
                        p.first-title-plat::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: -35px;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 16px;
                            background: #d1d6d9;
                        }
                        p.sec-title-plat {
                        }

                        p.active-title-plat {
                            font-size: 22px;
                            font-weight: bold;
                        }
                    }
                }
                ul.abroad {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 40px;
                    padding-bottom: 38px;
                    li {
                        width: 196px;
                        margin-right: 13px;
                        margin-bottom: 20px;
                        a {
                            display: block;
                            position: relative;
                            z-index: 0;
                            cursor: pointer;
                            img {
                                display: block;
                                width: 196px;
                                height: 120px;
                            }
                            h6 {
                                line-height: 38px;
                                background: #f5f7f9;
                                font-size: 20px;
                                font-weight: bold;
                                color: #2b221b;
                                text-align: center;
                                width: 196px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 0 6px;
                                box-sizing: border-box;
                            }
                            p {
                                position: absolute;
                                width: 196px;
                                height: 158px;
                                left: 0;
                                top: 0;
                                background: rgba(0, 0, 0, 0.7);
                                z-index: 2;
                                padding: 24px 20px 0 20px;
                                cursor: pointer;
                                display: none;
                                span {
                                    display: block;
                                    font-size: 14px;
                                    color: #fff;
                                    line-height: 22px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; // 控制多行的行数
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    box-sizing: border-box;
                                }
                            }
                        }
                        a:hover {
                            p {
                                display: block;
                            }
                        }
                    }
                    li:nth-child(4n + 4) {
                        margin-right: 0;
                    }
                }
                ul.domestic {
                    margin-top: 10px;
                    li {
                        padding-bottom: 14px;
                        border-bottom: 1px solid #eaeff3;
                        display: flex;
                        justify-content: space-between;
                        a {
                            display: block;
                            width: 42px;
                            height: 42px;
                            line-height: 42px;
                            text-align: center;
                            background: #f5fbff;
                            border-radius: 2px;
                            font-size: 14px;
                            color: #2b221b;
                            margin-right: 8px;
                            margin-top: 17px;
                        }
                        .platform-content {
                            width: 830px;
                            h6 {
                                font-size: 16px;
                                color: #2b221b;
                                line-height: 16px;
                                margin-bottom: 10px;
                                margin-top: 16px;
                                cursor: pointer;
                            }
                            div {
                                p {
                                    color: #828282;
                                    font-size: 14px;
                                    line-height: 15px;
                                    width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }

            .btn-wrap {
                //padding-top: 22px;
                //button {
                //    width: 300px;
                //    height: 30px;
                //    background: #fafbfc;
                //    border: none;
                //    border-radius: 4px;
                //    margin-bottom: 10px;
                //    cursor: pointer;
                //    display: flex;
                //    align-items: center;
                //    justify-content: center;
                //    cursor: not-allowed;
                //    img {
                //        width: 14px;
                //    }
                //}
                //ul {
                //    li {
                //        width: 300px;
                //        height: 56px;
                //        font-size: 18px;
                //        color: #0d1020;
                //        line-height: 56px;
                //        margin-bottom: 10px;
                //        text-align: center;
                //        background: url(../assets/img/oa///bg-link-default.png);
                //        background-size: 100% 100%;
                //        cursor: pointer;
                //    }
                //    li:hover {
                //        color: #fff;
                //        background: url(../assets/img/oa///bg-link-selected.png);
                //        background-size: 100% 100%;
                //    }
                //}

                button {
                    width: 300px;
                    height: 30px;
                    background: #fafbfc;
                    border: none;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 14px;
                    }
                }
                .down-btn {
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .button-wrap {
                    width: 300px;
                    max-height: 320px;
                    overflow: hidden;
                    position: relative;
                    .button-ul {
                        transition: 1s;
                    }
                }
                ul {
                    li {
                        width: 300px;
                        height: 56px;
                        font-size: 18px;
                        color: #0d1020;
                        line-height: 56px;
                        margin-bottom: 10px;
                        text-align: center;
                        background: url(../assets/img/oa/bg-link-default.png);
                        background-size: 100% 100%;
                        cursor: pointer;
                        a {
                            display: block;
                            width: 300px;
                            height: 56px;
                            font-size: 18px;
                            color: #0d1020;
                            line-height: 56px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                    }
                    li:hover {
                        color: #fff;
                        background: url(../assets/img/oa/bg-link-selected.png);
                        background-size: 100% 100%;
                        a {
                            color: #fff;
                        }
                    }
                }
            }



        }
        .bot-main {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            .cooperation-left {
                width: 880px;
                ul.coo-institution {
                    margin-top: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        margin-right: 11px;
                        margin-bottom: 22px;
                        a {
                            display: block;
                            img {
                                display: block;
                                width: 167px;
                                height: 146px;
                                border: 1px solid #eaeff3;
                                box-sizing: border-box;
                            }
                            p {
                                width: 167px;
                                height: 50px;
                                line-height: 18px;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                align-items: center;
                                background: #f5f7f9;
                                text-align: center;
                                color: #2b221b;
                                font-size: 15px;
                                box-sizing: border-box;
                                padding: 0 10px;
                            }
                        }
                    }
                    li:nth-child(5n + 5) {
                        margin-right: 0;
                    }
                }
                div.coo-book {
                    padding-bottom: 38px;
                    //            ul.com-book {
                    //                margin-top: 25px;
                    //                display: flex;
                    //                flex-wrap: wrap;
                    //                li {
                    //                    margin-right: 15px;
                    //                    margin-bottom: 5px;
                    //                    img {
                    //                        width: 160px;
                    //                        height: 225px;
                    //                        margin-bottom: 55px;
                    //                        cursor: pointer;
                    //                        box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                    //                    }
                    //                    p {
                    //                        width: 160px;
                    //                        line-height: 16px;
                    //                        font-size: 16px;
                    //                        text-align: center;
                    //                        cursor: pointer;
                    //                        overflow: hidden;
                    //                        text-overflow: ellipsis;
                    //                        white-space: nowrap;
                    //                    }
                    //                }
                    //                li:nth-child(5n + 5) {
                    //                    margin-right: 0;
                    //                }
                    //            }
                    ul.com-book {
                        position: relative;
                        margin-top: 25px;
                        z-index: 0;
                        display: flex;
                        padding-left: 23px;
                        box-sizing: border-box;
                        li {
                            margin-right: 12px;
                            width: 157px;
                            padding-bottom: 5px;
                            img {
                                width: 156px;
                                height: 223px;
                                margin-bottom: 40px;
                                //55px;
                                cursor: pointer;
                                box-shadow: 0px 15px 18px -15px #4f4a4a;
                            }
                            p {
                                color: #0d1020;
                                font-size: 15px;
                                width: 156px;
                                line-height: 16px;
                                text-align: center;
                                cursor: pointer;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                        li:last-child {
                            margin-right: 0;
                        }
                    }
                    ul.com-book::after {
                        content: '';
                        position: absolute;
                        width: 960px; //977px;
                        height: 100px;
                        //116px;
                        left: 50%;
                        bottom: -10px;
                        transform: translateX(-50%);
                        z-index: -2;
                        background: url(../assets/img/oa/bookshelf2.png);
                        background-size: 100% 100%;
                    }
                    //ul.com-book:last-child {
                    //    margin-top: 0;
                    //}
                }

            }
            .more-right {
                width: 300px;
                .dynamics-right {
                    width: 299px;
                    ul {
                        padding-top: 9px;
                        border: 1px solid #eaeff3;
                        padding-bottom: 2px;
                        li {
                            box-sizing: border-box;
                            padding: 0 19px 0 33px;
                            position: relative;
                            a {
                                width: 245px;
                                display: block;
                                padding-left: 10px;
                                font-size: 14px;
                                color: #2b221b;
                                line-height: 45px;
                                border-bottom: 1px solid #eaeff3;
                                box-sizing: border-box;
                                position: relative;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                            //a::before {
                            //    content: '';
                            //    width: 6px;
                            //    height: 6px;
                            //    background: #C30D23;
                            //    border-radius: 50%;
                            //    position: absolute;
                            //    left: -12px;
                            //    top: 50%;
                            //    transform: translateY(-50%);
                            //}
                        }
                        li::before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            background: #c30d23;
                            border-radius: 50%;
                            position: absolute;
                            left: 21px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        li:last-child {
                            a {
                                border-bottom: none;
                            }
                        }
                    }
                }
                .key-chart {
                    width: 300px;
                    height: 264px;
                    border: 1px solid #eaeff3;
                    margin-top: 22px;
                }

            }
        }
    }
}
